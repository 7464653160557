<div fxLayout="column" fxFlex="100" fxLayoutAlign="start" class="p-5">
  <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
  <form [formGroup]="form" *ngIf="formAvailable">
    <div class="flex flex-wrap">
      <mat-form-field appearance="fill" class="w-[12%] mr-2.5">
        <mat-label>Start Date</mat-label>
        <input formControlName="start_date" name="startDate" matInput [matDatepicker]="startPicker"
          placeholder="Choose a start date">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-[12%] mr-2.5">
        <mat-label>From</mat-label>
        <mat-select formControlName="from" [compareWith]="hourCompare">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-[12%] mr-2.5">
        <mat-label>End Date</mat-label>
        <input formControlName="end_date" name="endDate" matInput [matDatepicker]="endPicker"
          placeholder="Choose a end date">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #endPicker [startAt]="startDate"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-[12%] mr-2.5">
        <mat-label>To</mat-label>
        <mat-select formControlName="to" [compareWith]="hourCompare">
          <mat-option *ngFor="let hour of hours" [value]="hour">{{hour.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="">
        <mat-form-field appearance="fill" class="w-[92%] mr-2.5">
          <mat-label>Station</mat-label>
          <mat-select formControlName="station">
            <mat-option *ngFor="let terminal of terminalList" [value]="terminal">{{terminal.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="btn-reset-search">
        <button mat-flat-button color="primary" (click)="searchCashLog()" class="top-2.5 w-32 mr-5"
          [disabled]="isLoading">SEARCH</button>

        <button mat-stroked-button color="primary" (click)="reset()" class="top-2.5 w-32"
          [disabled]="isLoading">RESET</button>
      </div>
    </div>
  </form>
  <div>
    <table mat-table width="100%" matSort matSortActive="id" matSortDirection="desc" matSortDisableClear
      [dataSource]="dataSource" class="dark-bg-color">
      <ng-container matColumnDef="start_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Start Time </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Order"
          [ngClass.lt-md]="{'highlight-gray': true }">{{formatDate(element.drawer_start_time)}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="end_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header> End Time </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Placed">{{formatDate(element.drawer_end_time)}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="terminal">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Location </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="User">{{element.terminal_name}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paid_in">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Paid In </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Total">{{element.total_paid_in | currency:'USD'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="paid_out">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Paid Out </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Payment">{{element.total_paid_out | currency:'USD'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expected_amount">
        <mat-header-cell *matHeaderCellDef>Expected Amount</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Balance">{{element.expected_in_drawer | currency:'USD'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actual_amount">
        <mat-header-cell *matHeaderCellDef>Actual Amount</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Balance">{{element.actual_in_drawer | currency:'USD'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="difference">
        <mat-header-cell *matHeaderCellDef fxHide.lt-sm>Difference</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Balance" fxHide.lt-sm>
          <span [ngClass]="element.difference >= 0 ? 'text-[#4caf50]' : 'text-[#FF5733]'">
            {{element.difference | currency:'USD'}}
          </span>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Status">
          <span *ngIf="element.drawer_end_time">Closed</span>
          <span *ngIf="!element.drawer_end_time">Open</span>

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
  </div>
  <mat-paginator [length]="dataCount" [pageSize]="20" [pageSizeOptions]="[20,50]"
    (page)="yourHandler($event)"></mat-paginator>
</div>