import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {POSService} from 'src/app/_services/pos-service';
import {OrderService} from '../../_services/order.service';
import {JoinUserBaseComponent} from '../join-user-base/join-user-base.component';
import {OrdersWithPayment} from 'src/app/_models/order';
import {FrontFacingMqttService} from '../../_services/front-facing-mqtt.service';
import { Store } from 'src/app/_models/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import { ManualPrintControlComponent } from '../order-summary/manual-print-control/manual-print-control.component';
import { RefreshService } from 'src/app/_services/refresh.service';
import { Modules } from 'src/app/constants';
import { FloorPlanService } from 'src/app/_services/floor-plan.service';


@Component({
  selector: 'app-card-payment-consumer-choice',
  templateUrl: './card-payment-consumer-choice.component.html',
  styleUrls: ['./card-payment-consumer-choice.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardPaymentConsumerChoiceComponent implements OnInit, OnDestroy {

  public unsubscribe: Subject<null> = new Subject<null>();

  orderWithPayment: OrdersWithPayment;
  private destroySubject: Subject<void> = new Subject();


  constructor(public dialogRef: MatDialogRef<CardPaymentConsumerChoiceComponent>,
              private posService: POSService,
              private orderService: OrderService,
              public dialog: MatDialog,
              private ffmq: FrontFacingMqttService,
              private refreshService: RefreshService,
              private floorPlanService: FloorPlanService,
              @Inject(MAT_DIALOG_DATA) public data) {
                this.orderWithPayment = data.orderWithPayment;
                console.log('poooooooooooooooo', this.data);
                
              }

  isLoading = false;
  result;
  resultCode;

  terminalId: string;
  orderHash: string;
  posLoggedUser: string;
  terminalName: string;
  pinUser: string;
  issuccess = false;

  CancelSub;
  isDone;
  isConfirmed;
  store: Store;
  manualData: any;
  storeID: any;

  ngOnInit() {

    this.CancelSub =  this.ffmq.getPaymentCancel().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if (this.ffmq.decodeAndParse(data.payload).oncancel == true) {
        this.close();
      }
    });

    this.isDone = this.ffmq.getPaymentDoneStatus().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      if  (JSON.parse(data.payload.toString()).type === 'paymentCompletedDone') {
        this.isConfirmed = true;
      }
    });


    const orderHash = localStorage.getItem('order_hash');
    this.terminalId = localStorage.getItem('selectedTerminal');
    this.posLoggedUser = localStorage.getItem('posLoggedUser');
    this.terminalName = localStorage.getItem('selectedTerminalName');
    this.storeID = localStorage.getItem('currentStore');
    console.log('ssssssssssssssssss',this.storeID, typeof(this.storeID));
    
    this.pinUser = localStorage.getItem('pinUser');
    this.orderHash = orderHash;
    this.store = this.data.orderWithPayment.store;
    if(this.data.is_split){
      this.processSplitPayment(this.terminalId, this.data.order_id, this.data.toCharge, this.data.split_id);
    } else {
      this.processPayment(this.terminalId, this.data.orderWithPayment.bot_order.order_hash, this.data.toCharge ? this.data.toCharge : this.data.total);
    }

    this.manualData = {
      value: 1,
      order_hash: this.data.orderWithPayment.bot_order.order_hash,
      orderWithPayment: this.data.orderWithPayment
    }

    // Add to not show preview tip issue
    const keyData = {
      select: 'TIP',
      tip: {"name":"","value":'',"amount":''},
    };
    this.ffmq.publishTip(keyData);

  }

  done() {
    const isMobile = window.innerWidth <= 470;
    const dialogRef = this.dialog.open(ManualPrintControlComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        value: 1,
        order_hash: this.data.orderWithPayment.bot_order.order_hash,
        key: 'manual_card'
      }
    });
    dialogRef.afterClosed().subscribe((selectVal: any) =>{
      this.dialogRef.close(this.isConfirmed);
      this.refreshService.refreshModule('OPEN_TAB');
      this.orderService.orderDoneSubject.next();
      this.floorPlanService.floorCreatedUpdate.next(false);
    });

  }

  close() {
    this.dialogRef.close();
  }

  getFormattedDate(date) {
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const dateFormat = month + day + year;

    return dateFormat;
  }



  processPayment(terminalId, orderHash, total) {
    this.isLoading = true;
    const payload = {order_hash: orderHash,
      amount: `${total}`,
      payment_type: 'CREDIT',
      is_open_tab: this.data.isOpenTab,
      terminal_id: this.terminalId,
      cashier_id: this.posLoggedUser,
      terminal_name: this.terminalName,
      pin_user: this.pinUser
    };
    this.posService.posAuthorize(terminalId, payload)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((result) => {
      this.result = result;
      this.isLoading = false;
      this.ffmq.publishPaymentDone();
    }, error => {
        this.ffmq.publishPaymentFailed();
        this.isLoading = false;
        this.result = {result_code : 'Failure', message: 'Unable to complete the request. Server did not respond.'};
    });
  }

  processSplitPayment(terminalId, orderHash, total, split_id) {
    this.isLoading = true;
    const payload = {order_hash: orderHash,
      amount: total,
      split_amount: total,
      payment_type: 'SPLIT',
      is_open_tab: this.data.isOpenTab,
      terminal_id: this.terminalId,
      cashier_id: this.posLoggedUser,
      terminal_name: this.terminalName,
      split_id,
      pin_user: this.pinUser,
      p_type: 'CREDIT'
    };

    this.orderService.splitAuthorize(terminalId, payload, true)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((result) => {
      if (result.result_code === 'Approved') {
        this.orderService.processSplit(orderHash, payload).pipe(takeUntil(this.unsubscribe)).subscribe(d => {
          this.result = result;
          this.isLoading = false;
          this.issuccess = true;
        });
      } else {
        this.isLoading = false;
        this.issuccess = false;
        this.result = {result_code : 'Failure', message: 'Unable to complete the request. Server did not respond.'};
      }
    }, error => {
        this.isLoading = false;
        this.issuccess = false;
        this.result = {result_code : 'Failure', message: 'Unable to complete the request. Server did not respond.'};
    });
  }

  doneClick() {
    this.dialogRef.close(this.issuccess);
    this.refreshService.refreshModule('OPEN_TAB');
    this.orderService.orderDoneSubject.next();
  }

  joinUserBase() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(JoinUserBaseComponent, {
      width: '630px', data: {
        store: this.orderWithPayment.store,
        orderWithPayment: this.orderWithPayment
      }
    });
  }

  backClick() {
    this.updateTip();
  }

  checkStatus(terminalId, orderHash) {
    console.log(terminalId, orderHash);

    this.posService.posStatus(terminalId, {
      order_hash: orderHash,
      date: this.getFormattedDate(new Date())
    }).pipe(takeUntil(this.unsubscribe))
    .subscribe((status) => {
        this.result = status;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.destroySubject.next();
  }

  updateTip() {
    this.orderService.updateTip(this.data.orderWithPayment.bot_order.order_hash, 0, 'card-payment-updatetip').pipe(takeUntil(this.destroySubject)).subscribe((data: any) => {
      this.refreshService.refreshModule(Modules.POS_ORDER_SUMMARY);

      if (this.result.result_code == 'Cancelled') {
        this.orderWithPayment.payment.due_total = this.orderWithPayment.payment.due_total - this.orderWithPayment.payment.tip;
        this.orderWithPayment.updateTipCustom(0);
      }
      this.dialogRef.close();
    });
  }

}
