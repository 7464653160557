import { Component, OnInit, ViewChild } from '@angular/core';
import { LoyaltyPointsService } from 'src/app/_services/loyalty-points.service';
import { CounterService } from 'src/app/_services/counter.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe, formatDate } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from 'src/app/_models/store';
import { StoreService } from 'src/app/_services/store.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-user-loyalty',
  templateUrl: './user-loyalty.component.html',
  styleUrls: ['./user-loyalty.component.scss'],
  animations: [
    trigger('detailExpand', [state('collapsed, void', style({ height: '0px' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')), transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])
  ],
})
export class UserLoyaltyComponent implements OnInit {

  input: number = 0;
  storeId: any;
  countData: any;
  userCount: any;
  pointsTotal: Number;
  expiredPoints: Number;
  redeemedPoints: Number;
  pointsEarned: Number;
  outstansingPoints: Number;
  displyy = false;
  expandedRowId;
  selectedRow;
  phoneNumber: any;
  pageNumber: any;
  pageSize: any;
  filterValue: any;

  arrLoyalty = {
    "numbers": [],
    "colors": [],
    "isUpdate": 0,
  }
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  columnsToDisplay = ['name', 'phone_number', 'status',
    'user_order_visit', 'total_spend', 'current_points', 'points_redeemed', 'average_spend', 'last_visit', 'since'];
  dataSource: any;
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'action'];
  expandedElement: PeriodicElement | null;
  innerLoyaltyDisplayedColumns = ['order_id', 'order_date', 'order_time',
    'payment_type', 'total_spend', 'occasion', 'pos_online'];
  innerDatasource: any;
  currentPoints: any;
  userRedeemedPoints; any;
  Daterange: UntypedFormGroup;
  innerDaterange: UntypedFormGroup;
  from_date: any;
  to_date: any;
  from_date_inner: any;
  to_date_inner: any;
  search_str: any;
  filterCount: any;
  maxDateCheck: string;
  isLoading: boolean = false;
  store: Store;
  storeSub: Subscription;

  constructor(
    public loyaltyPointsService: LoyaltyPointsService,
    public countService: CounterService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private storeService: StoreService
  ) { }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.storeSub = this.storeService.current.subscribe((store) => {
      if (store) {
        this.store = Object.assign(new Store(), store);
      }
    });
    
    this.usersCountList(0);
    this.totalPoints();
    this.tableUserList();

    this.Daterange = this._formBuilder.group({
      from_date: [],
      to_date: [],
      search_str: []
    })

    this.innerDaterange = this._formBuilder.group({
      from_date_inner: [],
      to_date_inner: [],
    })
  }


  usersCountList(number) {
    this.loyaltyPointsService.getListOfUsers(this.storeId).subscribe((data: any) => {
      this.userCount = data.count_user;
      this.countData = data.data;
      this.displyy = true
      this.loyaltyGraph(number)
    });
  }

  loyaltyGraph(number) {
    this.countData.map(data => {
      this.arrLoyalty.numbers.push(data.percent)
      this.arrLoyalty.colors.push(data.color)
      this.arrLoyalty.isUpdate = number
    })
    this.loyaltyPointsService.setRefresh(this.arrLoyalty);
  }

  totalPoints() {
    this.loyaltyPointsService.getTotalPoints(this.storeId).subscribe((data: any) => {
      this.redeemedPoints = Math.round(data.total_redeem_points * 100) / 100;
      this.pointsEarned = Math.round(data.total_points * 100) / 100;
      this.expiredPoints = Math.round(data.total_expired_points * 100) / 100;
      this.outstansingPoints = Math.round(data.out_standing_point * 100) / 100;
    });
  }

  tableUserList() {
    this.isLoading = true;
    this.from_date = '';
    this.to_date = '';
    this.pageNumber = 0;
    this.pageSize = 10;
    this.search_str = '';
    this.loyaltyPointsService.getTableUserList(this.storeId, this.pageNumber, this.pageSize, this.search_str, '',
      '', this.from_date, this.to_date).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data.data);
        this.filterCount = data.total_count;
        this.selectedRow = null;
        this.expandedRowId = null;
        this.isLoading = false;

      });
  }

  onRowClicked(row) {
    this.selectedRow = row;
    this.phoneNumber = this.selectedRow.phone_number;
    this.from_date_inner = '';
    this.to_date_inner = ''
    this.orderList(this.phoneNumber);
  }


  innerFilterSubmit() {
    this.isLoading = true;
    const keyData = {
      "phone_number": this.phoneNumber,
      "store_id": this.storeId
    }
    if (this.innerDaterange.value.from_date_inner != null) {
      this.from_date_inner = formatDate(this.innerDaterange.value.from_date_inner, 'yyyy-MM-dd', 'en');
    } else {
      this.from_date_inner = '';
    }
    if (this.innerDaterange.value.to_date_inner != null) {
      this.to_date_inner = formatDate(this.innerDaterange.value.to_date_inner, 'yyyy-MM-dd', 'en');
    } else {
      this.to_date_inner = ''
    }
    this.loyaltyPointsService.getLastThreeOrder(keyData, this.from_date_inner, this.to_date_inner).subscribe((data: any) => {
      this.currentPoints = Math.round(data.current_total_points * 100) / 100;
      this.userRedeemedPoints = Math.round(data.total_redeemed_points * 100) / 100;
      this.innerDatasource = data.last_3_order;
      this.selectedRow == true;
      this.isLoading = false;

    });
  }

  innerClearFilter() {
    this.orderList(this.phoneNumber);
  }

  /* LAST THREE ORDER */
  orderList(number) {
    const keyData = {
      "phone_number": number,
      "store_id": this.storeId
    }
    this.from_date_inner = '';
    this.to_date_inner = ''
    this.loyaltyPointsService.getLastThreeOrder(keyData, this.from_date_inner, this.to_date_inner).subscribe((data: any) => {
      this.currentPoints = Math.round(data.current_total_points * 100) / 100;
      this.userRedeemedPoints = Math.round(data.total_redeemed_points * 100) / 100;
      this.innerDatasource = data.last_3_order;
      this.innerDaterange = this._formBuilder.group({
        from_date_inner: [],
        to_date_inner: [],
      })
    });
  }

  /* ADD POINTS */
  addPoints(p) {
    const keyData = {
      "phone_number": this.phoneNumber,
      "store_id": this.storeId,
      "points": p
    }
    this.loyaltyPointsService.userPointsAdd(keyData).subscribe((data: any) => {
      this.input = 0;
      this.orderList(this.phoneNumber);
    });
  }

  /* SUBTRACT POINTS */
  subtractPoints(p) {
    const keyData = {
      "phone_number": this.phoneNumber,
      "store_id": this.storeId,
      "points": p
    }
    if (this.currentPoints >= p) {
      this.loyaltyPointsService.userPointsSubtract(keyData).subscribe((data: any) => {
        this.input = 0;
        this.orderList(this.phoneNumber);
      });
    } else {
      this.snackBar.open("Input value must not be greater than current point balance", "OK", {
        duration: 1500,
      });
    }
  }

  longDate(w) {
    if (w.last_visit != null) {
      var ll = this.datePipe.transform(w.last_visit, 'M/dd/yyyy');
      var lastVisit = new Date(ll);
      var dateObj = new Date();
      var cc = this.datePipe.transform(dateObj, 'M/dd/yyyy');
      var currentDate = new Date(cc)
      var Time = currentDate.getTime() - lastVisit.getTime();
      var Days = Time / (1000 * 3600 * 24);
      return Days
    }
    else {
      return '-1'
    }
  }


  filterSubmit() {
    if (this.Daterange.value.from_date != null) {
      this.from_date = formatDate(this.Daterange.value.from_date, 'yyyy-MM-dd', 'en');
    } else {
      this.from_date = '';
    }
    if (this.Daterange.value.to_date != null) {
      this.to_date = formatDate(this.Daterange.value.to_date, 'yyyy-MM-dd', 'en');
    } else {
      this.to_date = ''
    }
    if (this.Daterange.value.search_str != null) {
      this.search_str = this.Daterange.value.search_str;
    } else {
      this.search_str = ''
    }
    this.loyaltyPointsService.getTableUserList(this.storeId, this.pageNumber, this.pageSize, this.search_str, this.sort.active,
      this.sort.direction, this.from_date, this.to_date).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data.data);
        this.filterCount = data.total_count;
      });
  }

  clearFilter() {
    this.from_date = '';
    this.to_date = '';
    this.pageNumber = 0;
    this.pageSize = 10;
    this.search_str = '';
    this.paginator.pageSize = 10;
    this.paginator.pageIndex = 0;
    this.sort.active = '';
    this.sort.direction = '';
    this.loyaltyPointsService.getTableUserList(this.storeId, this.pageNumber, this.pageSize, this.search_str, '',
      '', this.from_date, this.to_date).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data.data);
        this.filterCount = data.total_count;
        this.selectedRow = null;
        this.expandedRowId = null;
        this.Daterange = this._formBuilder.group({
          from_date: [],
          to_date: [],
          search_str: []
        })
      });
  }

  exportData() {
    // Prepare date range and other parameters
    this.from_date = this.Daterange.value.from_date
      ? formatDate(this.Daterange.value.from_date, 'yyyy-MM-dd', 'en')
      : '';
    this.to_date = this.Daterange.value.to_date
      ? formatDate(this.Daterange.value.to_date, 'yyyy-MM-dd', 'en')
      : '';
    this.search_str = this.Daterange.value.search_str || '';

    // Call the service to fetch and download the file
    this.loyaltyPointsService
      .ExportLoyaltyPointData(
        this.storeId,
        this.pageNumber,
        this.pageSize,
        this.search_str,
        this.sort.active,
        this.sort.direction,
        this.from_date,
        this.to_date
      )
      .subscribe((blob: Blob) => {
        // Create a link element for file download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'loyalty_user_data.xlsx'; // File name
        link.click();

        // Cleanup
        window.URL.revokeObjectURL(link.href);
      });
  }

  yourHandler(q) {
    if (this.Daterange.value.from_date != null) {
      this.from_date = formatDate(this.Daterange.value.from_date, 'yyyy-MM-dd', 'en');
    } else {
      this.from_date = ''
    }
    if (this.Daterange.value.to_date != null) {
      this.to_date = formatDate(this.Daterange.value.to_date, 'yyyy-MM-dd', 'en');
    } else {
      this.to_date = ''
    }
    if (this.Daterange.value.search_str != null) {
      this.search_str = this.Daterange.value.search_str;
    } else {
      this.search_str = ''
    }
    this.pageNumber = q.pageIndex;
    this.pageSize = q.pageSize;
    if (this.filterValue == undefined) {
      this.filterValue = '';
    }
    if (this.sort.active == undefined) {
      this.sort.active = ''
      this.sort.direction = ''
    }
    this.loyaltyPointsService.getTableUserList(this.storeId, this.pageNumber, this.pageSize, this.search_str, this.sort.active,
      this.sort.direction, this.from_date, this.to_date).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data.data);
      });
  }

  sortingHeader() {
    this.loyaltyPointsService.getTableUserList(this.storeId, this.pageNumber, this.pageSize, this.search_str, this.sort.active,
      this.sort.direction, this.from_date, this.to_date).subscribe((data: any) => {
        this.dataSource = new MatTableDataSource(data.data);
      });
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}

export interface PeriodicElement {
  name: any;
  phone_number: any;
  status: any;
  user_order_visit: any;
  total_spend: any;
  average_spend: any;
  last_visit: any;
  since: any;
}



