import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {FormUtils} from 'src/app/_helpers/form-utils';
import {Store} from 'src/app/_models/store';
import {StoreService} from 'src/app/_services/store.service';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
  selector: 'app-special-hours',
  templateUrl: './special-hours.component.html',
  styleUrls: ['./special-hours.component.scss'],
  providers:[
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class SpecialHoursComponent implements OnInit {

  isLoading = false;
  form;
  store:Store;

  hours;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SpecialHoursComponent>,
    private formUtils:FormUtils,
    private storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      this.hours = formUtils.buildTimeOptions();
    }

  ngOnInit() {
    this.store = this.data.store;
    this.form = this.formBuilder.array(this.store.special_hour.map(sh => {
      var fromOptions = null
      var toOptions = null
      if(sh.hour){
        let from12 = this.formUtils.get12HourValue(sh.hour.from_hour, sh.hour.from_min)
        fromOptions = {
          hour: sh.hour.from_hour,
          min: sh.hour.from_min,
          key: from12.replace(' am', '').replace(' pm', ''),
          value: from12
        }

        let to12 = this.formUtils.get12HourValue(sh.hour.to_hour, sh.hour.to_min)
        toOptions = {
          hour: sh.hour.to_hour,
          min: sh.hour.to_min,
          key: to12.replace(' am', '').replace(' pm', ''),
          value: to12}
      }

      return this.formBuilder.group({
        store_id: this.store.id,
        special_date: moment(sh.special_date).toDate(),
        special_end_date: moment(sh.special_end_date).toDate(),
        is_open: sh.is_open,
        from: [sh.from_time, Validators.required],
        to: [sh.to_time, Validators.required],
      })
    }))

  }

  hourCompare(left, right){
    return left.value === right.value;
  }

  toggle(i){
    this.form.controls[i].value.is_open = !this.form.controls[i].value.is_open

    if(!this.form.controls[i].value.is_open){
      // this.getTimeControl('from', i).disable()
      // this.getTimeControl('to', i).disable()
    }else{
      this.getTimeControl('from', i).enable()
      this.getTimeControl('to', i).enable()
    }
    this.getTimeControl('from', i).updateValueAndValidity();
    this.getTimeControl('to', i).updateValueAndValidity();
  }

  getTimeControl(key, i){
    return (this.form as UntypedFormArray).controls[i].get(key)
  }

  addNew(){
    (this.form as UntypedFormArray).push(this.formBuilder.group({
      store_id: this.store.id,
      special_date: [null, Validators.required],
      special_end_date: [null, Validators.required],
      is_open: true,
      from: [null, Validators.required],
      to:[null, Validators.required]
    }))
  }

  delete(i){
    (this.form as UntypedFormArray).removeAt(i)
  }

  onSubmit(){
    let payload = {
      store_id: this.store.id,
      hours: this.form.value
    }
    console.log(payload);

    this.storeService.updateSpecialHour(payload).subscribe(data => {
      this.dialogRef.close()
    })
  }

}
