<div class="flex flex-col p-4">
  <h2 class="text-xl font-semibold mb-4">Loyalty Point Expiry</h2>
  <form [formGroup]="loyaltyForm" (ngSubmit)="onUpdate()" class="flex items-center gap-4 mb-6">
    <mat-form-field appearance="fill" class="flex-grow">
      <mat-label>Loyalty Points Expiry Time (in days)</mat-label>
      <input matInput id="expiryTime" formControlName="expiryTime" type="number" placeholder="Enter expiry time in days"
        required />
      <mat-error *ngIf="expiryTime.invalid && (expiryTime.dirty || expiryTime.touched)">
        Please enter a valid number of days.
      </mat-error>
    </mat-form-field>

    <!-- Button with dynamic styles -->
    <button mat-raised-button type="submit" [disabled]="loyaltyForm.invalid" [ngClass]="{
        '!bg-blue-500 !hover:bg-blue-600 !text-white': !loyaltyForm.invalid,
        '!bg-gray-400 !text-gray-600 !cursor-not-allowed': loyaltyForm.invalid
      }" class="px-4 py-[14px] rounded-md font-medium w-[120px] !h-[55px] text-base" style="position: relative;
    bottom: 10px;">
      Update
    </button>
  </form>

  <div class="flex justify-between items-center mb-4 mt-5">
    <p class="text-sm text-gray-600">
      Every dollar spent equals a point earned! Manage Tiers and Rewards Below:
    </p>
    <button class="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md font-medium w-24"
      (click)="noTierRow()">
      Add Tier
    </button>
  </div>

  <div class="grid gap-4" *ngIf="pointsList?.length != 0">
    <!-- <div class="flex justify-end mb-4">
      <button class="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md font-medium w-24"
        (click)="noTierRow()">
        Add Tier
      </button>
    </div> -->
    <span *ngIf="pointsList">
      <div class="grid grid-cols-[2fr_auto_2fr_auto_auto_auto] gap-4 items-center my-5" *ngFor="let point of pointsList">
        <!-- Points Input Field -->
        <mat-form-field appearance="fill" class="w-full open-close-filters">
          <input type="number" min="0" id="username" name="username" #username matInput value="{{point.points}}"
             oninput="validity.valid||(value='')">
        </mat-form-field>

        <!-- Points Unlocks Label -->
        <span class="text-blue-500 font-medium">Points Unlocks</span>

        <!-- Card Value Input Field -->
        <mat-form-field appearance="fill" class="w-full open-close-filters">
          <input type="number" min="0" oninput="validity.valid||(value='')" id="username2" name="username2" #username2 matInput value="{{point.card_value}}">
        </mat-form-field>

        <!-- Dollar Gift Card Label -->
        <span class="text-blue-500 font-medium">Dollar Gift Card</span>

        <!-- Save Button -->
        <button class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md font-medium w-24"
          (click)="updatePointValue(username.value, username2.value, point)">
          Save
        </button>

        <!-- Delete Button -->
        <button class="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md font-medium w-24"
          (click)="deleteTierRow(point)">
          Delete
        </button>
      </div>
    </span>
  </div>
</div>