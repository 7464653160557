<table mat-table multiTemplateDataRows *ngIf="tempDs" [id]="menuGroup.id" [dataSource]="tempDs"
  class="menu-group-item border-1p-grey !table-fixed !w-full no-select" cdkDropList [cdkDropListData]="tempDs.data"
  (cdkDropListDropped)="drop($event)">
  <ng-container matColumnDef="name">
    <td mat-cell *matCellDef="let element">
      <div [ngSwitch]="element.type">
        <div *ngSwitchCase="'menu_group'" class="heading">
          {{element.data.name}}
          <span *ngIf="element.data.subgroup">&nbsp;-&nbsp;{{element.data.subgroup}}</span>
        </div>
        <div *ngSwitchCase="'menu_item_group'" class="sub-heading">
          <span><mat-icon>drag_indicator</mat-icon></span>
          <span class="relative bottom-[6px]">{{element.data.name}} </span>
          <span class="float-right relative top-[5px]">{{element.data.starting_price | currency:'USD'}}</span>
        </div>
        <div *ngSwitchCase="'add'" class="add-heading">
          {{element.data.name}}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="star">
    <td mat-cell *matCellDef="let element" class="clean-icon">
      <mat-menu #operations="matMenu">
        <button *ngIf="element.type ==='menu_group'" mat-menu-item type="button"
          (click)="moveMenuGroup('top', element.data.id); false">Move to top</button>
        <button *ngIf="element.type ==='menu_item_group'" mat-menu-item type="button"
          (click)="moveMenuItemGroup('top', element.data.id); false">Move to top</button>

        <button *ngIf="element.type ==='menu_group'" mat-menu-item type="button"
          (click)="moveMenuGroup('up', element.data.id); false">Move up</button>
        <button *ngIf="element.type ==='menu_item_group'" mat-menu-item type="button"
          (click)="moveMenuItemGroup('up', element.data.id); false">Move up</button>

        <button *ngIf="element.type ==='menu_group'" mat-menu-item type="button"
          (click)="moveMenuGroup('down', element.data.id); false">Move down</button>
        <button *ngIf="element.type ==='menu_item_group'" mat-menu-item type="button"
          (click)="moveMenuItemGroup('down', element.data.id); false">Move down</button>

        <button *ngIf="element.type ==='menu_group'" mat-menu-item type="button"
          (click)="moveMenuGroup('bottom', element.data.id); false">Move to bottom</button>
        <button *ngIf="element.type ==='menu_item_group'" mat-menu-item type="button"
          (click)="moveMenuItemGroup('bottom', element.data.id); false">Move to bottom</button>

        <button *ngIf="element.type ==='menu_group'" mat-menu-item type="button"
          (click)="duplicateMenuGroup(element.data.id); false">Create copy</button>
        <button *ngIf="element.type ==='menu_item_group'" mat-menu-item type="button"
          (click)="duplicateMenuItemcat(element.data.id); false">Create copy</button>

        <button mat-menu-item type="button" *ngIf="element.type ==='menu_group'" (click)="menuSorting()">Sort (A-Z /
          Z-A)</button>
        <mat-divider></mat-divider>
        <button *ngIf="element.type ==='menu_group'" mat-menu-item type="button"
          (click)="deleteMenuGroup(element.data); false">Delete</button>
        <button *ngIf="element.type ==='menu_item_group'" mat-menu-item type="button"
          (click)="deleteMenuItemGroup(element.data); false">Delete</button>
      </mat-menu>

      <button *ngIf="element.type != 'add'" mat-icon-button [matMenuTriggerFor]="operations">
        <mat-icon>more_vert</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="highlight">
      <div class="example-element-detail" *ngIf="element" [@detailExpand]="isExpanded(menuGroup.id, element)">
        <div [ngSwitch]="element.type" class="full-width">

          <div *ngSwitchCase="'menu_group'" class="flex-wrap">
            <form class="form" [formGroup]="element.form" (ngSubmit)="updateMenuGroup(element.form.value)">
              <input type="hidden" formControlName="id">
              <div class="flex w-full xs:flex-col mb-11">
                <div class="w-1/3 mr-2.5 xs:w-full vxs:w-full">
                  <mat-form-field class="w-full xs:!w-full xs:m-0">
                    <input matInput placeholder="Menu Name" formControlName="name" autofocus>
                    <mat-hint></mat-hint>
                  </mat-form-field>
                  <mat-form-field class="w-full xs:!w-full xs:m-0">
                    <mat-label>BG color</mat-label>
                    <input matInput type="color" placeholder="Menu BG Color" formControlName="color" pattern="^#([0-9A-Fa-f]{6})$">
                    <mat-hint></mat-hint>
                  </mat-form-field>
                  <div class="imagedata flex w-full justify-center">
                    <ng-template *ngIf="menuGroup.image; then imageBlock else uploadBlock"></ng-template>
                    <ng-template #imageBlock>
                      <a (click)="imageDelete('image')" style="cursor: pointer" *ngIf="menuGroup.image">
                        <mat-icon
                          style=" float: right;margin-left: 98px; margin-top:5px;position: absolute;">delete</mat-icon>
                      </a>
                      <img [src]="menuGroup.image" height="120" width="120" class="pointer" matTooltip="Click to change"
                        (click)="openCatandSubcatImageDialog(element, 'category')">
                    </ng-template>
                    <ng-template #uploadBlock>
                      <button mat-stroked-button type="button"
                        (click)="openCatandSubcatImageDialog(element, 'category')">Upload Category Image</button>
                    </ng-template>
                  </div>
                </div>
                <div class="w-1/3 mr-2.5 xs:w-full vxs:w-full">
                  <mat-form-field class="w-full xs:!w-full xs:m-0">
                    <input matInput placeholder="Subcategory Name" formControlName="subgroup" autofocus>
                    <mat-hint></mat-hint>
                  </mat-form-field>
                  <mat-form-field class="w-full xs:!w-full xs:m-0">
                    <mat-label>Text color</mat-label>
                    <input matInput type="color" placeholder="Menu color" formControlName="text_color" pattern="^#([0-9A-Fa-f]{6})$">
                    <mat-hint></mat-hint>
                  </mat-form-field>
                  <div class="imagedata flex w-full justify-center">
                    <ng-template *ngIf="menuGroup.sub_image; then imageBlocks else uploadBlocks"></ng-template>
                    <ng-template #imageBlocks>
                      <a (click)="imageDelete('sub_image')" style="cursor: pointer" *ngIf="menuGroup.sub_image">
                        <mat-icon
                          style=" float: right;margin-left: 98px; margin-top:5px;position: absolute;">delete</mat-icon>
                      </a>
                      <img [src]="menuGroup.sub_image" height="120" width="120" class="pointer"
                        matTooltip="Click to change" (click)="openCatandSubcatImageDialog(element, 'sub-category')">
                    </ng-template>
                    <ng-template #uploadBlocks>
                      <button mat-stroked-button type="button"
                        (click)="openCatandSubcatImageDialog(element, 'sub-category')">Upload Sub Category
                        Image</button>
                    </ng-template>
                  </div>
                </div>
                <div class="w-1/3 xs:w-full vxs:w-full">
                  <mat-form-field class="w-full xs:!w-full xs:m-0">
                    <mat-select formControlName="assignto_printer" placeholder="Assign to Printer" multiple>
                      <mat-option *ngFor="let opt of printerSettings" [value]="opt.id">{{opt.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="w-full xs:!w-full xs:m-0">
                    <mat-label>Select Icon</mat-label>
                    <mat-select formControlName="menu_item_icon_id" placeholder="Select Icon" (selectionChange)="onIconSelected($event)" panelClass="custom-select-panel">
                      
                      <!-- <mat-select-trigger>
                        {{ getSelectedIconOptionLabel(element.form.get('menu_item_icon_id')?.value) }}
                      </mat-select-trigger> -->
                      <mat-select-trigger>
                        {{ element.form.get('menu_item_icon_id')?.value ? getSelectedIconOptionLabel(element.form.get('menu_item_icon_id')?.value) : 'Select an option' }}
                      </mat-select-trigger>
                  
                      <div class="sticky top-0 z-10 bg-white p-2">
                        <mat-form-field class="w-full p-0 cursor-default search-option">
                          <input matInput placeholder="Search..." (keyup)="filterIconOptions($event.target.value)" (click)="$event.stopPropagation()">
                        </mat-form-field>
                      </div>
                  
                      <mat-option *ngFor="let opt of filteredIconList" [value]="opt.id">
                        {{ opt.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-checkbox class="" formControlName="hide_to_kds">Hide from KDS</mat-checkbox>
                </div>
              </div>
              <div class="flex items-center justify-between pb-4">
                <app-menu-category-availability class="self-start" [menuGroupName]="menuGroup.name"
                  [subCatName]="menuGroup.subgroup" [store]="store"></app-menu-category-availability>
                <div class="flex gap-2">
                  <button mat-stroked-button color="primary" type="button"
                    (click)="expandedElement.set(menuGroup.id, null);ngOnInit();false"
                    class="pull-right cancel-btn xs:top-[5px]">Cancel</button>
                  <button mat-stroked-button color="primary" class="pull-right save-btn xs:top-[5px]">Save</button>
                </div>
              </div>
              <div formGroupName="availability" class="full-width message-table xs:overflow-x-scroll">
                <table class="table menu-name-width !table-auto">
                  <tr class="">
                    <th class="Menu-item-n">Menu Item</th>
                    <th class="Menu-item-n">Upload Image</th>
                    <th class="Availabi-lity">Availability</th>
                    <th class="Availabi-lity">Reset</th>
                    <th class="Availabi-qty">Available Qty</th>
                    <th class="Tax-able">Taxable</th>
                    <th class="POS-only">POS Only</th>
                  </tr>
                  <tr>
                    <td class="sub-header"></td>
                    <td class="sub-header"></td>
                    <td class="sub-header">
                      <mat-checkbox class="mod-froup-checkbox full-width" #selectAllAvl
                        [checked]="isAllSelected(element.form.controls.availability.controls, 'is_available')"
                        (change)="toggleAll(element.form.controls.availability.controls,'is_available', $event)">{{selectAllAvl.checked
                        ? 'Deselect' : 'Select' }} all</mat-checkbox>
                    </td>
                    <td class="sub-header"></td>
                    <td class="sub-header"></td>
                    <td class="sub-header">
                      <mat-checkbox class=" mod-froup-checkbox full-width" #selectAllQty
                        [checked]="isAllSelected(element.form.controls.availability.controls, 'is_taxable')"
                        (change)="toggleAll(element.form.controls.availability.controls,'is_taxable', $event)">{{selectAllQty.checked
                        ? 'Deselect' : 'Select' }} all</mat-checkbox>
                    </td>
                    <td class="sub-header">
                      <mat-checkbox class=" mod-froup-checkbox full-width" #selectAllPos
                        [checked]="isAllSelected(element.form.controls.availability.controls, 'is_pos_only')"
                        (change)="toggleAll(element.form.controls.availability.controls,'is_pos_only', $event)">{{selectAllPos.checked
                        ? 'Deselect' : 'Select' }} all</mat-checkbox>
                    </td>
                  </tr>
                  <tr [formGroup]="control" *ngFor="let control of element.form.controls.availability.controls">
                    <td  class="sub-header xs:w-4/5">{{control.value.name}} {{control.value.menu_item_group}}</td>
                    <td  class="sub-header">
                      <button *ngIf="!control.value.hasImage" style="color:#fff; background-color: #448AFF;" mat-stroked-button type="button" (click)="openImageDialog(control.value.igId)">Upload</button>
                      <button *ngIf="control.value.hasImage" style="color:#448AFF " mat-stroked-button type="button" (click)="openImageDialog(control.value.igId)">Update</button>
                    </td>
                    <td  class="sub-header">
                      <mat-checkbox class="full-width" formControlName="is_available"></mat-checkbox>
                    </td>
                    <td class="sub-header">
                      <mat-form-field class="xs:w-4/5">
                        <mat-select formControlName="reset" placeholder="Reset">
                          <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td class="sub-header">
                      <mat-form-field class="xs:w-28">
                        <input matInput placeholder="Qty" formControlName="available_qty" type="number">
                      </mat-form-field>
                    </td>
                    <td class="sub-header xs:w-28 ">
                      <mat-checkbox class="full-width xs:w-28" formControlName="is_taxable"></mat-checkbox>
                    </td>
                    <td class="sub-header xs:w-28">
                      <mat-checkbox class="full-width xs:w-28" formControlName="is_pos_only"></mat-checkbox>
                    </td>
                  </tr>
                </table>
              </div>
            </form>
          </div>

          <div *ngSwitchCase="'menu_item_group'">
            <ng-container *ngIf="selectedMenuItemGroupForm">
              <form class="form" [formGroup]="selectedMenuItemGroupForm"
                (ngSubmit)="updateMenuItemGroup(selectedMenuItemGroupForm.value)">
                <div class="flex flex-row gap-3 flex-wrap items-center">
                  <div class="imagedata w-40 ">
                    <ng-template
                      *ngIf="selectedMenuItemGroup?.latest_image; then imageBlock else uploadBlock"></ng-template>
                    <ng-template #imageBlock>
                      <a (click)="delete()" style="cursor: pointer"
                        *ngIf="selectedMenuItemGroup?.latest_image[0].image">
                        <mat-icon
                          style=" float: right;margin-left: 98px; margin-top:5px;position: absolute;">delete</mat-icon>
                      </a>
                      <img [src]="selectedMenuItemGroup?.latest_image[0].image" height="120" width="120" class="pointer"
                        matTooltip="Click to change" (click)="openImageDialog()">
                    </ng-template>
                    <ng-template #uploadBlock>
                      <button mat-stroked-button type="button" (click)="openImageDialog()">Upload Image</button>
                    </ng-template>
                    <br />
                    <a *ngIf="selectedMenuItemGroup?.latest_image" style="cursor: pointer;margin: 10px;"
                      (click)="openImageViewDialog()">View All Photos</a>
                  </div>
                  <div class="metadata">
                    <input type="hidden" formControlName="id">
                    <mat-form-field class="width-100">
                      <input matInput placeholder="Item name" formControlName="name">
                    </mat-form-field>
                    <!-- <mat-form-field class="width-25">
                      <input matInput placeholder="Starting price" formControlName="starting_price">
                    </mat-form-field> -->
                    <mat-form-field class="width-100">
                      <input matInput placeholder="Description" formControlName="description">
                    </mat-form-field>
                  </div>
                </div>

                <p class="text-xl">Item Portions</p>
                <p class="text-base flex-wrap mb-[8px]">Enter the different portions and prices for this item. Use “Standard” if
                  the item comes in only one size.</p>

                <div formArrayName="menu_items"
                  *ngFor="let item of selectedMenuItemGroupForm.get('menu_items').controls; let i = index">
                  <div class="flex flex-col border-bottom" [formGroupName]="i">
                    <p>Portion ID : {{ item.get('id')?.value }}</p>
                    <div class="flex flex-row xs:gap-2 items-center  flex-wrap  justify-between">
                      <div class="flex flex-row gap-2">
                        <mat-form-field class="w-[50%] xs:w-[42%]" subscriptSizing="dynamic">
                          <input type="text" placeholder="Portion" matInput formControlName="portion"
                            [matAutocomplete]="auto">
                          <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of portions" [value]="option">
                              {{option}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%]">
                          <input matInput placeholder="Base price" type="number" formControlName="base_price">
                        </mat-form-field>
                      </div>
                      <div class="flex flex-row gap-2">
                        <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%]">
                          <mat-select formControlName="reset" placeholder="Reset">
                            <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%]">
                          <input matInput placeholder="Food Cost" type="number" formControlName="food_cost">
                        </mat-form-field>
                      </div>
                      <div class="flex flex-row gap-2 items-center">
                        <mat-checkbox class="" formControlName="is_available">Available</mat-checkbox>
                        <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[30%] xs:ml-[15px]">
                          <input matInput placeholder="Qty" formControlName="available_qty" type="number">
                        </mat-form-field>
                        <mat-checkbox class="" formControlName="is_taxable">Taxable</mat-checkbox>
                      </div>
                      <div class="flex flex-row gap-2 items-center xs:w-full">
                        <mat-checkbox formControlName="is_pos_only" class="w-[85%] xs:w-[84%]">POS Only</mat-checkbox>
                        <button class="delete" type="button" mat-icon-button [matMenuTriggerFor]="opsMenu">
                          <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #opsMenu="matMenu">
                          <button mat-menu-item type="button" (click)="moveMenuItem('top', item.value); false">Move to
                            top</button>
                          <button mat-menu-item type="button" (click)="moveMenuItem('up', item.value); false">Move
                            up</button>
                          <button mat-menu-item type="button" (click)="moveMenuItem('down', item.value); false">Move
                            down</button>
                          <button mat-menu-item type="button" (click)="moveMenuItem('bottom', item.value); false">Move
                            to
                            bottom</button>
                          <mat-divider></mat-divider>
                          <button mat-menu-item (click)="deleteMenuItem(item.value); false">Delete</button>
                        </mat-menu>
                      </div>
                    </div>
                    <div class="flex flex-row xs:gap-2 items-center  flex-wrap ">
                      <div class="flex flex-row gap-2">
                        <mat-form-field appearance="fill">
                          <mat-label>Assign ID</mat-label>
                          <mat-select formControlName="joined_with" 
                                      (selectionChange)="onOptionSelected($event, item)" 
                                      #select 
                                      panelClass="custom-select-panel" 
                                      class="w-[50%] xs:w-[42%]">
                            <mat-select-trigger>
                              {{ getSelectedOptionLabel(item.get('joined_with')?.value) }}
                            </mat-select-trigger>

                            <div class="sticky top-0 z-10 bg-white p-2">
                              <mat-form-field class="w-full p-0 cursor-default search-option">
                                <input matInput placeholder="Search..." 
                                      (keyup)="filterOptions($event.target.value)" 
                                      (click)="$event.stopPropagation()">
                              </mat-form-field>
                            </div>

                            <div>
                              <mat-option *ngFor="let option of filteredmenuItemId" [value]="option.id">
                                {{ option.id }}
                              </mat-option>
                            </div>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field subscriptSizing="dynamic" class="w-[50%] xs:w-[42%] h-[67px]"
                          *ngIf="store?.feature_flag['barcode_scanner_feature']">
                          <input matInput placeholder="Barcode" type="text" formControlName="barcode">
                        </mat-form-field>
                      </div>
                      <div class="flex flex-row gap-2">
                        <button type="button" *ngIf="store?.feature_flag['barcode_scanner_feature']"
                          class="relative left-4"
                          style="border: 2px solid #0C83FF; padding: 5px; border-radius: 5px; color: #0C83FF"
                          (click)="generateBarcode(item.get('id').value,item.get('portion').value,item.get('display_name').value,true)">
                          Download Barcode</button>
                        <button type="button" *ngIf="store?.feature_flag['barcode_scanner_feature']"
                          class="relative left-8"
                          style="border: 2px solid #0C83FF; padding: 5px; border-radius: 5px; color: #0C83FF"
                          (click)="generateBarcode(item.get('id').value,item.get('portion').value,item.get('display_name').value,false)">
                          Print Barcode</button>
                          <span class="relative left-8 top-[6px]" *ngIf="item.get('reset_time')?.value">Reset Time: {{ item.get('reset_time')?.value }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-row flex-wrap xs:flex-nowrap items-center button-center gap-2  justify-between my-5">
                  <div class="button-center xs:!w-[40%]">
                    <button class="pull-left items-center button-center add-btn xs:!w-[100%]" mat-stroked-button
                      color="primary" type="button" (click)="addPortionExMenuItemGroup(); false">Add Portion</button>
                  </div>
                  <div class="button-center flex flex-row items-center flex-wrap gap-2 xs:!w-[60%]">
                    <button class="button-center cancel-btn xs:!w-[45%]" mat-stroked-button color="primary"
                      type="button" (click)="expandedElement.set(menuGroup.id, null);ngOnInit(); false">Cancel</button>
                    <button class="button-center save-btn xs:!w-[45%]" mat-stroked-button color="primary" type="submit"
                      [disabled]="!selectedMenuItemGroupForm.valid">Save</button>
                  </div>
                </div>
              </form>
            </ng-container>
          </div>

          <div *ngSwitchCase="'add'">
            <form class="form" [formGroup]="newMenuItemGroupForm"
              (ngSubmit)="addNewMenuItemGroup(newMenuItemGroupForm.value)">
              <!-- <input type="hidden" formControlName="menu_group_id"> -->

              <mat-form-field class="width-100">
                <input matInput placeholder="Item name" formControlName="name">
              </mat-form-field>
              <mat-form-field class="width-100">
                <input matInput placeholder="Description" formControlName="description">
              </mat-form-field>
              <p class="heading">Item Portions</p>
              <p class="sub-heading">Enter the different portions and prices for this item. Use “Standard” if the item
                comes in only one size.</p>
              <div formArrayName="menu_items"
                class="flex flex-row flex-wrap items-center xs:items-start xs:flex-nowrap gap-2  my-5"
                *ngFor="let item of newMenuItemGroupForm.get('menu_items').controls; let i = index">
                <div class="border-bottom flex flex-row flex-wrap items-center xs:items-start gap-2 xs:contents"
                  [formGroupName]="i">
                  <div class="flex flex-row flex-wrap items-center gap-3 xs:gap-0 ">
                    <div class="flex xs:contents">
                      <mat-form-field class="w-[50%] xs:w-[48%]">
                        <input type="text" placeholder="Portion" matInput formControlName="portion"
                          [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let option of portions" [value]="option">
                            {{option}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>

                      <mat-form-field class="w-[50%] xs:w-[48%] ml-[10px]">
                        <input matInput placeholder="Base price" type="number" formControlName="base_price">
                      </mat-form-field>
                      <mat-form-field class="w-[50%] xs:w-[48%] ml-[10px]"
                        *ngIf="store?.feature_flag['barcode_scanner_feature']">
                        <input matInput placeholder="Barcode" type="text" formControlName="barcode">
                      </mat-form-field>
                    </div>
                    <div class="flex xs:contents">
                      <mat-form-field class="w-[50%] xs:w-[48%]">
                        <mat-select formControlName="reset" placeholder="Reset">
                          <mat-option *ngFor="let opt of resetOptions" [value]="opt.key">{{opt.value}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="w-[50%] xs:w-[48%] ml-[10px]">
                        <input matInput placeholder="Food Cost" type="number" formControlName="food_cost">
                      </mat-form-field>
                    </div>
                    <div class="">
                      <mat-checkbox class="" formControlName="is_available">Available</mat-checkbox>
                      <mat-checkbox class="" formControlName="is_taxable">Taxable</mat-checkbox>
                      <mat-checkbox class="" formControlName="is_pos_only">POS Only</mat-checkbox>
                    </div>
                    <button class="delete xs:left-[76%]" type="button" mat-stroked-button
                      (click)="deleteMenuItemFromNewForm(); false">
                      Delete
                    </button>
                  </div>
                  <!-- <div class="form-ops">
                    <button class="delete" type="button" mat-stroked-button (click)="deleteMenuItemFromNewForm(); false">
                      Delete
                    </button>
                  </div> -->
                  <div style="clear: both"></div>
                </div>
              </div>
              <div class="action button-center xs:flex xs:flex-row xs:gap-2">
                <button class="pull-left button-center add-btn" mat-stroked-button color="primary" type="button"
                  (click)="addPortionNewMenuItemGroup(); false">Add Portion</button>
                <button class="pull-right save-btn" mat-stroked-button color="primary" type="submit"
                  [disabled]="!newMenuItemGroupForm.valid">Save</button>
                <button class="pull-right cancel-btn" mat-stroked-button color="primary" type="button"
                  (click)="expandedElement.set(menuGroup.id, null);ngOnInit(); false">Cancel</button>
                <div style="clear: both"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
    [class.example-expanded-row]="expandedElement.get(menuGroup.id) === element"
    (click)="expandRow(menuGroup.id, element)" cdkDrag [cdkDragData]="element"
    [cdkDragStartDelay]="isTouchDevice ? 100 : 0">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>