import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from 'src/app/_models/store';
import { LoyaltyPointsService } from 'src/app/_services/loyalty-points.service';
import { StoreService } from 'src/app/_services/store.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loyalty-settings',
  templateUrl: './loyalty-settings.component.html',
  styleUrls: ['./loyalty-settings.component.scss']
})
export class LoyaltySettingsComponent implements OnInit {

  loyaltyForm: FormGroup;
  storeId: any;
  store: Store
  pointsList: any;
  expire_day: number;
  private destroySubject: Subject<null> = new Subject<null>();
  constructor(public loyaltyPointsService: LoyaltyPointsService, private fb: FormBuilder, private storeService: StoreService,) { }

  ngOnInit() {
    this.storeId = localStorage.getItem('currentStore');
    this.loyaltyForm = this.fb.group({
      expiryTime: [null, [Validators.required, Validators.min(1)]]
    });

    this.storeService.current.pipe(takeUntil(this.destroySubject)).subscribe(store => {
      if (store) {
        this.store = Object.assign(new Store(), store);
        console.log('ffffffffffffff', this.store);
        this.updateStoreData();
        
      }
    });
    this.getPointsList();
  }

  get expiryTime() {
    return this.loyaltyForm.get('expiryTime');
  }

  updateStoreData() {
    this.storeService.getMerchant(this.store).subscribe(data => {
      this.store = Object.assign(new Store(), data['store']);
      if (this.store && this.store['loyalty_point_expire_time']) {
        this.loyaltyForm.get('expiryTime')?.setValue(this.store['loyalty_point_expire_time']);
      }
    })
  }

  onUpdate(): void {
    if (this.loyaltyForm.valid) {
      const expiryTime = this.loyaltyForm.value.expiryTime;
      const sendData = {
        "store_id": this.storeId,
        "expiry_time": expiryTime
      }
      this.loyaltyPointsService.updateLoyaltyExpireDays(sendData).subscribe((data: any) => {
        if (data) {
          this.updateStoreData();
        }
      })
    } else {
      console.log('Form is invalid');
    }
  }

  getPointsList() {
    this.loyaltyPointsService.getTierlist(this.storeId).subscribe((data: any) => {
      this.pointsList = data.data;
    })
  }

  updatePointValue(p, cv, c) {
    const sendData = {
      "id": c.id,
      "points": p,
      "card_value": cv
    }
    this.loyaltyPointsService.updatePointsValue(sendData).subscribe((data: any) => {
    })
  }

  noTierRow() {
    const sendData = {
      "points": 0,
      "card_value": 0,
      "store_id": this.storeId
    }
    this.loyaltyPointsService.createTierPoints(sendData).subscribe((data: any) => {
      this.getPointsList();
    })
  }


  addTierRow(row) {
    const sendData = {
      "id": row.id,
      "points": row.points,
      "card_value": row.card_value,
      "store_id": row.store
    }
    this.loyaltyPointsService.createTierPoints(sendData).subscribe((data: any) => {
      this.getPointsList();
    })
  }

  deleteTierRow(row) {
    const sendData = {
      "id": row.id,
      "points": row.points,
      "card_value": row.card_value,
      "store_id": row.store,
      "is_deleted": true
    }
    this.loyaltyPointsService.deletedTier(sendData).subscribe((data: any) => {
      this.getPointsList();
    })
  }

}
