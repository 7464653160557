import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {POSService} from 'src/app/_services/pos-service';
import {OrderService} from '../../_services/order.service';
import {FrontFacingMqttService} from '../../_services/front-facing-mqtt.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Store } from 'src/app/_models/store';


@Component({
  selector: 'app-card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardPaymentComponent implements OnInit, OnDestroy     {

  public unsubscribe: Subject<null> = new Subject<null>();

  store: Store;
  constructor(public dialogRef: MatDialogRef<CardPaymentComponent>,
              private posService: POSService,
              private orderService: OrderService,
              public dialog: MatDialog,
              private ffmq: FrontFacingMqttService,
              @Inject(MAT_DIALOG_DATA) public data) { }

  isLoading = false;
  result;
  resultCode;

  terminalId: string;
  orderHash: string;
  manualData:any;
  storeID: any;

  ngOnInit() {
    const orderHash = localStorage.getItem('order_hash');
    const terminal_id = localStorage.getItem("selectedTerminal")
    this.storeID = localStorage.getItem('currentStore');
    console.log('mmmmmmmmmmmmmmmmm',this.storeID, typeof(this.storeID));
    this.terminalId = terminal_id;
    this.orderHash = orderHash;
    this.store = this.data.orderWithPayment.store;
    this.processPayment(this.terminalId, this.orderHash, this.data.toCharge);
    this.manualData = {
      value: 1,
      order_hash: orderHash
    }
  }

  getFormattedDate(date) {
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const dateFormat = month + day + year
    console.log(dateFormat);


    return dateFormat;
  }

  processPayment(terminalId, orderHash, total) {
    this.isLoading = true;
    const payload = {order_hash: orderHash, amount: total, payment_type: 'CREDIT', is_open_tab: this.data.isOpenTab};
    this.posService.posAuthorize(terminalId, payload)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((result) => {
      this.result = result;
      this.isLoading = false;
      this.ffmq.publishPaymentDone();
    }, error => {
      this.ffmq.publishPaymentFailed();
      this.isLoading = false;
      this.result = {result_code : 'Failure', message: 'Unable to complete the request. Server did not respond.'}
    });
  }


  doneClick() {
    this.dialogRef.close(true);
    // this.orderService.orderDoneSubject.next();
    // localStorage.removeItem('order_hash');
    // this.data.form.reset();
    // this.data.total = 0;
    // this.data.subTotal = 0;
  }

  backClick() {
    this.dialogRef.close();
  }

  checkStatus(terminalId, orderHash){
    console.log(terminalId, orderHash);

    this.posService.posStatus(terminalId, {
      order_hash: orderHash,
      date: this.getFormattedDate(new Date())
    }).pipe(takeUntil(this.unsubscribe))
    .subscribe((status) => {
        this.result = status;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
