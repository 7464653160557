import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FeedbacksService } from 'src/app/_services/feedbacks.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-rating-pie-chart',
  templateUrl: './rating-pie-chart.component.html',
  styleUrls: ['./rating-pie-chart.component.scss']
})
export class RatingPieChartComponent {

  chart: any;
  @ViewChild('dChart', { static: false }) dChart: ElementRef;
  @Input() chartLabels: any = [];
  jsonArray: any = [];
  @Input() cutOut: number = 20;
  backgroundColors: any = [];
  graphTimeout: any;
  @Input() allData: any;
  
  constructor(public feedbackService: FeedbacksService) {
    this.feedbackService.getRefresh().subscribe((value: any) => {
      if (this.chart) this.chart.destroy();
      if (value) {
        this.backgroundColors = value.colors;
        this.jsonArray = value.numbers;
        if (value.isUpdate == 1) {
          this.updateGraph();
        }
      }
    });
  }

  ngOnInit() {
    this.graphTimeout = setTimeout(() => {
      this.updateGraph();
    }, 3000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['allData'] && !changes['allData'].firstChange) {
      if (this.chart) this.chart.destroy();
      setTimeout(() => {
        this.updateGraph();
      }, 3000);
    }
  }


  ngAfterViewInit() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  updateGraph() {
    if (!this.dChart) return;
    const cvs: any = this.dChart.nativeElement;
    this.chartLabels = this.allData.pie_chart_data.map((item: any) => `Rating ${item.rating}`);
    this.chart = new Chart(cvs, {
      type: 'doughnut',
      data: {
        labels: this.chartLabels, 
        datasets: [
          {
            data: this.allData.pie_chart_data.map((item: any) => item.count),  
            backgroundColor: this.backgroundColors,  
            fill: false,
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: this.cutOut,  
        legend: {
          display: false, 
        },
        tooltips: {
          enabled: true, 
          callbacks: {
            label: (tooltipItem, data) => {
              const index = tooltipItem.index;
              const label = `Rating ${this.allData.pie_chart_data[index].rating}`; 
              const count = this.allData.pie_chart_data[index].count;
              const percentage = this.allData.pie_chart_data[index].percentage.toFixed(2); 
              return `${label}: ${count} (${percentage}%)`;
            }
          }
        },
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: 'Total',
                font: {
                  size: 20,
                  weight: 'bold'
                }
              },
              {
                text: this.allData.total_feedbacks
              }
            ]
          }
        }
      },
    });
  }
  

  ngOnDestroy(): void {
    clearTimeout(this.graphTimeout);
    window.removeEventListener('resize', this.onResize);
    if (this.chart) this.chart.destroy();
  }

  onResize = () => {
    if (this.chart) {
      this.chart.resize();
    }
  }
}
