import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MqttService} from 'ngx-mqtt';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TerminalService} from '../_services/terminal.service';
import {TerminalStatusService} from '../_services/terminal-status.service';
import {IClientSubscribeOptions} from 'mqtt/types/lib/client-options';
import {FrontFacingMqttService} from '../_services/front-facing-mqtt.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RefreshService} from '../_services/refresh.service';

@Component({
  selector: 'app-front-display-status',
  templateUrl: './front-display-status.component.html',
  styleUrls: ['./front-display-status.component.scss']
})
export class FrontDisplayStatusComponent implements OnDestroy, OnInit, OnChanges{

  isConnected = false;
  private destroySubject: Subject<void> = new Subject();
  tabs = new Set<string>()

  @Input() terminalId: string;

  constructor(private terminalService: TerminalStatusService,
              private frontFacingService: FrontFacingMqttService,
              private snb: MatSnackBar,
              private refreshService: RefreshService,
              private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.terminalService.current.subscribe(terminal => {
      console.log("device", terminal);
      this.listenToDisplayTopic();

      setTimeout(() => {
        this.refresh();
        this.ref.detectChanges();
      }, 2000)

    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  refresh(){
    console.log("refreshing");
    this.frontFacingService.sendHello();
  }

  listenToDisplayTopic(){
    this.frontFacingService.listenForDeviceConnections()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(data => {
      console.log("device", data);
      console.log(this.tabs);
      if(this.tabs.size <= 0){
        this.tabs.add(data.tabId)
        this.isConnected = true;
        this.snb.open('Customer-facing display connected!',
          'OK', {duration: 1000})
      } else if (this.tabs.size > 1) {
        const snbSub = this.snb.open('Multiple customer-facing displays detected. Please make sure only one display is connected!',
          'RESET');
        snbSub.afterDismissed().pipe(takeUntil(this.destroySubject)).subscribe(data => {
          console.log(data);
          if(data.dismissedByAction){
            this.tabs.clear();
          }
        })
      }

      if(!this.isConnected){
        this.refresh();
      }
    })

    this.frontFacingService.listenForDeviceDisconnection().subscribe(data => {
      console.log("device disconnect", data);
      if(this.tabs.has(data.tabId)){
        this.tabs.delete(data.tabId);
        this.isConnected = this.tabs.size >= 1;
        this.snb.open('Customer-facing display disconnected!',
          'OK', {duration: 1000})
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.unsubscribe();
  }

}
