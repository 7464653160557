import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OrdersComponent} from './orders/orders.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './_guards/auth.gaurd';
import {LoginLayoutComponent} from './login-layout/login-layout.component';
import {HomeLayoutComponent} from './home-layout/home-layout.component';
import {AccountComponent} from './account/account.component';
import {SupportComponent} from './support/support.component';
import {InfoComponent} from './info/info.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {PasswordRestConfirmComponent} from './login/password-rest-confirm/password-rest-confirm.component';
import {StoreMenuComponent} from './info/store-menu/store-menu.component';
import {StripeComponent} from './account/stripe/stripe.component';
import {OffersComponent} from './offers/offers.component';
import {InsightsComponent} from './insights/insights.component';
import {SkillComponent} from './info/skill/skill.component';
import {MessageComponent} from './message/message.component';
import {CampaignComponent} from './campaign/campaign.component';
import {UsersComponent} from './users/users.component';
import {AttendantComponent} from './attendant/attendant.component';
import {OffersV2Component} from './offers-v2/offers-v2.component';
import {TimeClockComponent} from './time-clock/time-clock.component';
import {PosSettingsComponent} from './pos-settings/pos-settings.component';
import {LoyaltyComponent} from './loyalty/loyalty.component';
import {ReportDialogComponent} from './dashboard/report/report-dialog/report-dialog.component';
import {DigitalDisplayComponent} from './digital-display/digital-display.component';
import {SettlementsComponent} from './settlements/settlements.component';
import { DigitalScreensComponent } from './digital-display/digital-screens/digital-screens.component';
import { TvScreenPreviewComponent } from './digital-display/column-management/tv-screen-preview/tv-screen-preview.component';
import { ReferralComponent } from './referral/referral.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FloorPlanComponent } from './floor-plan/floor-plan.component';

const routes: Routes = [
  { path: 'login', component: LoginLayoutComponent,
  children:[
    { path: '', component: LoginComponent },
    { path: 'password-reset', component: ForgotPasswordComponent },
    { path: 'password-reset/:uid/:token', component: PasswordRestConfirmComponent }
  ]},
  {path: 'monthly/:store_id/:month/:year', component: ReportDialogComponent},
  { path: 'digital-display-screen/:storeId/:tv_id/:type', component: DigitalScreensComponent },
  { path: 'tv-management-screen/:storeId/:id/:type', component: TvScreenPreviewComponent },
  {
    path: 'home', component: HomeLayoutComponent,

    children:[
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'orders', component: OrdersComponent, canActivate: [AuthGuard]},
      {path: 'offers', component: OffersComponent},
      {path: 'insights', component: InsightsComponent},
      {path: 'info', component: InfoComponent},
      {path: 'menu', component: StoreMenuComponent},
      {path: 'skill', component: SkillComponent},
      {path: 'account', component: AccountComponent},
      {path: 'account/stripe', component: StripeComponent},
      {path: 'support', component: SupportComponent},
      {path: 'messages', component: MessageComponent},
      {path: 'campaign', component: CampaignComponent},
      {path: 'users', component: UsersComponent},
      {path: 'attendant', component: AttendantComponent},
      {path: 'loyalty', component: LoyaltyComponent},
      {path: 'referral', component: ReferralComponent},
      {path: 'offers-v2', component: OffersV2Component},
      {path: 'time-clock', component: TimeClockComponent},
      {path: 'pos-settings', component: PosSettingsComponent},
      {path: 'digital-display', component: DigitalDisplayComponent},
      {path: 'settlements', component: SettlementsComponent},
      {path: 'floor-plan', component: FloorPlanComponent},
      {path: 'feedback', component: FeedbackComponent},
    ]
  },
  { path: '**', redirectTo: 'login' }
];

export const routing = RouterModule.forRoot(routes, {});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    BrowserAnimationsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
