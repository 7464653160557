<div *ngIf="isLoading; then processingBlock else resultBlock"></div>

<ng-template #processingBlock>
  <p class="cardPayment cardPayment-mobile xs:!text-2xl">
    Card Payment In Progress:
  </p>
  <mat-spinner class="spinner"></mat-spinner>
  <!-- <button mat-raised-button class="back" (click)="backClick()">
    Cancel
  </button> -->
</ng-template>

<ng-template #resultBlock>
  <div *ngIf="result.result_code === 'Approved' && result.response.response.transactionResult.responseCode === 'A'; then approvedBlock else notApprovedBlock"></div>
  <ng-template #approvedBlock>
    <span *ngIf="!store.feature_flag['manual_print_standard_checkout']">
      <p class="cardPayment cardPayment-mobile">
        Card Payment:
      </p>
      <p class="approving">Approved x</p>
      <p class="codeStyle approved-message">{{result.message}}</p>
      <button mat-raised-button class="doneButton  mb-4" color="primary" (click)="doneClick()">
        Ok
      </button>
    </span>
    <!-- WITH PRINT OPTIONS -->
    <span *ngIf="store.feature_flag['manual_print_standard_checkout']">
      <p class="cardPayment cardPayment-mobile cardPaymentNew">
        Card Payment:
      </p>
      <p class="approving approvingNew">Approved</p>
      <p class="codeStyle approved-message">{{result.message}}</p>
      <app-manual-print-control [manualData]="manualData" (selectionEvent)="doneClick()"></app-manual-print-control>
    </span>
    
  </ng-template>

  <ng-template #notApprovedBlock>
    <p class="cardPayment cardPayment-mobile">
      Card Payment:
    </p>
    <p class="notApproving">Not Approved</p>
    <p class="codeStyle">{{result.message}}</p>
    <p *ngIf="result.response.response.transactionResult.responseCode === 'D' && result.response.response.transactionResult.responseMessage === 'Partial authorization is not supported.'" class="codeStyle partialMessage">
      INSUFFICIENT FUNDS, PLEASE TRY A DIFFERENT PAYMENT TYPE. Customer will notice a partial authorization, but instruct that the partial authorization will auto void and not charge account.
    </p>

    <button mat-raised-button type="button" class="statusButton mb-4" (click)="checkStatus(terminalId, orderHash)">
      Status
    </button>

    <button mat-raised-button class="doneButton mb-4" color="primary" (click)="backClick()">
      Back
    </button>
  </ng-template>

</ng-template>

