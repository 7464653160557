import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MenuServiceV2 } from 'src/app/_services/menu-v2.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MenuResetOptions } from 'src/app/_models/store';

@Component({
  selector: 'app-edit-item-dialog',
  templateUrl: './edit-item-dialog.component.html',
  styleUrls: ['./edit-item-dialog.component.scss']
})
export class EditItemDialogComponent implements OnInit {

  private destroySubject: Subject<void> = new Subject();
  form: FormGroup;
  menuGroupItem: any;
  maxHeight: string;
  resetOptions = MenuResetOptions;
  portions = [];
  constructor(
    private menuService: MenuServiceV2,
    public dialogRef: MatDialogRef<EditItemDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any  // This is the passed 'item'
  ) { }

  ngOnInit() {
    // Initialize empty form
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      starting_price: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl(''),
      menu_items: new UntypedFormArray([]) // Empty form array at the beginning
    });

    // Call getItem to fetch and populate form with API data
    this.getItem();
    this.calculateHeight();
    window.addEventListener('resize', this.calculateHeight.bind(this));
  }

  calculateHeight() {
    const viewportHeight = window.innerHeight;
    const headerHeight = 200; // Approximate height of the header
    const buttonHeight = 120;  // Approximate height of the button section
    
    // Calculate available space for the scrollable content
    this.maxHeight = `calc(${viewportHeight}px - ${headerHeight + buttonHeight}px)`;
  }

  // Fetch item data from API and populate the form
  getItem() {
    const currentStore = localStorage.getItem('currentStore');
    this.menuService.getStoreMenuItemGroup(currentStore, this.data.id)
      .pipe(takeUntil(this.destroySubject))
      .subscribe(data => {
        this.menuGroupItem = data;

        // Patch form values with the data from the API
        this.form.patchValue({
          id: data.id,
          name: data.name,
          starting_price: data.starting_price,
          description: data.description
        });

        // Populate the menu_items FormArray
        const menuItemsFormArray = this.form.get('menu_items') as UntypedFormArray;
        menuItemsFormArray.clear();  // Clear any previous form items

        data.menu_items.forEach((mi) => {
          const menuItemForm = new UntypedFormGroup({
            menu_group_id: new UntypedFormControl(data.id, Validators.required),
            id: new UntypedFormControl(mi.id, Validators.required),
            portion: new UntypedFormControl(mi.portion, Validators.required),
            description: new UntypedFormControl(mi.description),
            base_price: new UntypedFormControl(mi.base_price, Validators.required),
            barcode: new UntypedFormControl(mi.barcode),
            food_cost: new UntypedFormControl(mi.food_cost),
            is_available: new UntypedFormControl(mi.is_available, Validators.required),
            is_taxable: new UntypedFormControl(mi.is_taxable, Validators.required),
            is_pos_only: new UntypedFormControl(mi.is_pos_only, Validators.required),
            available_qty: new UntypedFormControl(mi.available_qty),
            reset: new UntypedFormControl(mi.reset),
            name: new UntypedFormControl(mi.name),
            menu_item_group_name: new UntypedFormControl(mi.menu_item_group_name),
            display_name: new UntypedFormControl(mi.display_name),
            joined_with: new UntypedFormControl(mi.joined_with)
          });

          // Add each menu item to the FormArray
          menuItemsFormArray.push(menuItemForm);
        });

        console.log('Form populated with API data', this.form.value);
      });
  }

  // Update the menu item group
  updateMenuItemGroup(values) {
    const currentStore = localStorage.getItem('currentStore');
    this.menuService.updateStoreMenuItemGroup(currentStore, this.data.id, JSON.stringify(values))
      .pipe(takeUntil(this.destroySubject))
      .subscribe(data => {
        this.snackBar.open('Item has been updated', 'OK', { duration: 2000 });
        this.dialogRef.close();
      });
  }

  onNoClick(event: Event): void {
    event.preventDefault();
    this.dialogRef.close();
  }
  ngOnDestroy() {
    window.removeEventListener('resize', this.calculateHeight.bind(this));
  }
}
